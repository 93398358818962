import React from "react";
import styled from "styled-components";

const StyledGameContainer = styled.div`
  display: grid;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
  overflow-y: auto;
`;

const StyledGameRows = styled.div`
  overflow-y: scroll;
  height: 100%;
  grid-template-columns: subgrid;
  height: 80vh;
  -webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

const StyledGame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid var(--grey);
  background: var(--background);
`;

const StyledPlayers = styled.div`
  margin: 0 10px;
`;

export default function MatchHistory({ data }) {
  const matches = data.allDataSheetsData.edges;
  return (
    <StyledGameContainer>
      <StyledGameRows>
        {matches
          .sort((a, b) => (a.node.date < b.node.date ? 1 : -1))
          .map(game => {
            let blue = [
              game.node.blue1,
              game.node.blue2,
              game.node.blue3,
              game.node.blue4,
              game.node.blue5,
            ].filter(x => !!x);
            let red = [
              game.node.red1,
              game.node.red2,
              game.node.red3,
              game.node.red4,
              game.node.red5,
            ].filter(x => !!x);
            return (
              <StyledGame
                key={game.node.id}
                style={{
                  "--background":
                    game.node.winner === "blue" ? "var(--blue)" : "var(--red)",
                }}
              >
                <StyledPlayers>
                  <p style={{ fontWeight: "bold" }}>
                    {game.node.winner === "blue" ? "Victory" : "Defeat"}
                  </p>
                  {blue.map(player => {
                    return <p key={player}>{player}</p>;
                  })}
                  <p>
                    {game.node.date.match(/.{1,2}/g)[0]}.
                    {game.node.date.match(/.{1,2}/g)[1]}.
                    {game.node.date.match(/.{1,2}/g)[2] +
                      game.node.date.match(/.{1,2}/g)[3]}
                  </p>
                </StyledPlayers>
                <StyledPlayers>
                  <p style={{ fontWeight: "bold" }}>
                    {game.node.winner === "red" ? "Victory" : "Defeat"}
                  </p>
                  {red.map(player => {
                    return <p key={player}>{player}</p>;
                  })}
                </StyledPlayers>
              </StyledGame>
            );
          })}
      </StyledGameRows>
    </StyledGameContainer>
  );
}
